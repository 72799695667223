<template>
  <section class="auth-section">
    <div class="container mob-container text-center">
      <img src="/images/logo_h.svg" class="logo" alt="logo"/>
      <!--            <LogoSvg class="logo"/>-->
      <h1>{{ $t('reset_password') }}</h1>
      <InputGroup label="Email" type="email" v-model="email" @enter="reset"/>
      <div class="form-group">
        <button @click="reset" class="btn-themed w-100">{{ $t('reset') }}</button>
      </div>
      <div class="error" v-if="error">{{ error }}</div>
      <div class="alert alert-info" v-if="success">{{ $t('email_with_further_instructions_is_sent_to_your_email') }}</div>
      <div class="mt-4 text-left">
        <router-link class="text-muted small text-underlined" to="/login">{{ $t('authorization') }}</router-link>
      </div>

    </div>
  </section>
</template>

<script>
import InputGroup from "@/views/parts/general/form/InputGroup"
// import LogoSvg from "@/assets/svg/logo.svg"
import { UsersService } from '@/services/api.service'
import '@/assets/sass/auth.scss'

export default {
  components: {
    // LogoSvg,
    InputGroup
  },
  data() {
    return {
      email: "",
      error: "",
      success: false,
      message: ""
    }
  },
  mounted() {

  },
  methods: {
    reset() {
      UsersService.reset(this.email).then(res => {
        let r = this.apiResponse(res)
        if(r.success) {
          this.email = ''
          this.success = true
          this.$successPlain(this.$t('reset_success'))
        } else {
          this.error = r.data
        }
      }).catch(err => {
        this.errResponse(err)
      })
    }
  },
  watch: {
    email() {
      this.error = ''
      this.success = false
    },
  },
  metaInfo() {
    return {
      title: this.$t('reset_password'),
    }
  },
}
</script>

<style lang="scss" scoped>

</style>


